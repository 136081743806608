
import { Component, Prop, Watch, Mixins } from 'vue-property-decorator';
import DataFormatControlsContainer, { ChartDataType } from '@/components/DataFormatControlsContainer.vue';
import Highcharts from "highcharts";
import { ConsolidatedByFortnightSerieDTO } from '@/data/dto/consolidated-by-fortnight.dto';
import HighchartsMixins from '@/mixins/highcharts.mixin';
import HighchartsUtil from "@/utils/highcharts.util";
import { numberToBrString } from "@/utils/number.util";

@Component({
  components: {
    DataFormatControlsContainer,
  }
})
export default class ConsolidatedByFortnightChart extends Mixins(HighchartsMixins) {
  @Prop() private series!: ConsolidatedByFortnightSerieDTO[];
  @Prop() private fortnights!: string[];
  @Prop({ default: 0 }) private defaultDecimalPlaces!:number;

  yKey: ChartDataType = 'audience';

  //geração de opções do highcharts através de função, para garantir acesso ao contexto atual do Vue
  //dentro das funções de callback do highcharts (através do parâmetro context)
  generateChartOptions(context: ConsolidatedByFortnightChart, categories: string[]): Highcharts.Options {
    return {
      chart: {
        type: 'column',
      },
      credits: {
        enabled: false,
      },
      title: {
        text: undefined,
      },
      plotOptions: {
        series:{
          dataLabels: {
            enabled: true,
            formatter: function(): number | string {
              return numberToBrString(this.y, context.decimalPlaces);
            }
          },
        }
      } as Highcharts.PlotOptions,
      xAxis: {
        categories: categories,
      } as Highcharts.XAxisOptions,
      yAxis: {
        title: {
          text: undefined,
        },
        labels: {
          enabled: false,
        },
      },
      tooltip: {
        shared: true,
        useHTML: true,
      } as Highcharts.TooltipOptions,
      series: [],
    };
  }

  onChartDataTypeChange(yKey: ChartDataType): void {
    this.yKey = yKey;
    this.mountChartSeriesYAxis(this.chartOptions.series as any, yKey);
  }

  mountChartSeriesYAxis(series: ConsolidatedByFortnightSerieDTO[], yKey = this.yKey): void {
    this.chartOptions.series = series.map((item) => ({
      ...item,
      data: HighchartsUtil.setYAxisValue(item.data, yKey),
    })) as Highcharts.SeriesOptionsType[];
  }

  @Watch('series')
  renderChart(series: ConsolidatedByFortnightSerieDTO[]): void {
    this.mountChartSeriesYAxis(series);
  }

  mounted(): void {
    this.chartOptions = this.generateChartOptions(this, this.fortnights);
    this.renderChart(this.series);
    this.decimalPlaces = this.defaultDecimalPlaces;
  }
}
